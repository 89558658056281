<template>
  <div class="popup-container" :class="{'is-active': modelValue}" @click="relToggle = false">
    <!-- popup -->
    <div class="popup" id="popup-educourse-e03">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">{{ editable.rtrcn ? '신청취소' : '불참(취소)/회차변경' }}</h3>
          </header>
          <!-- popup-inner > popup-content -->
          <div class="popup-content agree-wrap">
            <div class="agree-content">
              <!-- kb-form-fields -->
              <div class="kb-form-fields">
                <!-- kb-form-row:차수명 -->
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title"><label class="kb-form-label"><span
                      class="kb-form-label-text">차수명</span></label></div>
                  <div class="kb-form-column"><p class="kb-form-text">{{ target.crseNm }}</p></div>
                </div>
                <!-- kb-form-row:연수기간 -->
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">연수기간</span>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <p class="kb-form-text">
                      {{ timestampToDateFormat(target.bgngDt, 'yyyy.MM.dd') }} ~
                      {{ timestampToDateFormat(target.endDt, 'yyyy.MM.dd') }}</p>
                  </div>
                </div>
                <!-- kb-form-row:구분 -->
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">구분</span>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <div class="kb-form-group">
                      <div class="kb-form-check kb-form-check-inline" v-if="editable.rtrcn">
                        <input v-model="regCrseAplySttDcd" type="radio" class="kb-form-check-input" name="chk_01"
                               id="chk_01_01" :value="crseAplyDcds.REG_CRSE_APLY_STT_CANCEL">
                        <label for="chk_01_01" class="kb-form-check-label"><span class="text">취소</span></label>
                      </div>
                      <div class="kb-form-check kb-form-check-inline" v-if="editable.absd">
                        <input v-model="regCrseAplySttDcd" type="radio" class="kb-form-check-input" name="chk_01"
                               id="chk_01_03" :value="crseAplyDcds.REG_CRSE_APLY_STT_NOSW">
                        <label for="chk_01_03" class="kb-form-check-label"><span class="text">불참(취소)</span></label>
                      </div>
                      <div class="kb-form-check kb-form-check-inline" v-if="editable.seqChg">
                        <input v-model="regCrseAplySttDcd" type="radio" class="kb-form-check-input" name="chk_01"
                               id="chk_01_02" :value="crseAplyDcds.REG_CRSE_APLY_STT_CHANGE">
                        <label for="chk_01_02" class="kb-form-check-label"><span class="text">회차변경</span></label>
                      </div>
                    </div>
                  </div>
                </div>

                <template v-if="regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE">
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">차수명</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <SortButtonSelect
                          v-model="chgDistCrseSn"
                          v-model:toggle="relToggle"
                          title="차수를 선택해주세요."
                          :options="otherRels"
                          sequence-key="distCrseSn"
                          name-key="crseNm"
                          :is-row="true"
                          :is-num="true"
                          @selected="changeThis"
                      />
                    </div>

                  </div>
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">연수기간</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <p class="kb-form-text">
                        {{ timestampToDateFormat(chgTarget.bgngDt, 'yyyy.MM.dd') }} ~
                        {{ timestampToDateFormat(chgTarget.endDt, 'yyyy.MM.dd') }}</p>
                    </div>
                  </div>
                  <div class="kb-form-row">
                    <div class="kb-form-column kb-form-column-title">
                      <label class="kb-form-label">
                        <span class="kb-form-label-text">사유</span>
                      </label>
                    </div>
                    <div class="kb-form-column">
                      <input v-model="reason" type="text" class="kb-form-control" placeholder="사유를 입력하세요.">
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <div class="agree-check">
              <div class="kb-form-check">
                <input v-model="isAgree" type="checkbox" class="kb-form-check-input" id="chk_agree_01">
                <label class="kb-form-check-label" for="chk_agree_01">
                  <span v-if="regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CANCEL" class="text">연수신청을 취소하고, 연수대상자에서 제외됨을 확인합니다.</span>
                  <span v-else-if="regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE" class="text">다른 회차로의 변경을 신청합니다. 반려되면 기존입과가 유지됩니다.</span>
                  <span v-else-if="regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_NOSW" class="text">입과된 연수의 불참을 신청합니다. 반려되면 입과가 유지됩니다.</span>
                </label>
              </div>
            </div>
          </div>

          <!-- popup-inner > popup-buttons -->
          <div class="popup-buttons">
<!--            <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">취소</span></button>-->
            <button class="kb-btn kb-btn-primary kb-btn-lg" @click="clickChange"><span class="text">확인</span></button>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
</template>

<script>


import {computed, reactive, ref} from 'vue';
import {getResult, isSuccess, timestampToDateFormat} from '@/assets/js/util';
import {getListFunc} from '@/assets/js/ui.init';
import {
  ACT_GET_CRSE_REL_LIST,
  ACT_UPDATE_CRSE_REG_APLY,
  ACT_GET_CRSE_DIST_OBJ_APPLY,
  ACT_GET_CRSE_DIST_OBJ_LRNER
} from '@/store/modules/course/course';
import SortButtonSelect from '@/components/common/SortButtonSelect';
import {crseAplyDcds} from '@/assets/js/modules/common/common';
import {useAlert} from '@/assets/js/modules/common/alert';
import {useStore} from 'vuex';
import {ACT_GET_LEARN_HISTORY_LIST} from '@/store/modules/learn/learn';

export default {
  name: 'ApplyStatusEditModal',
  components: {SortButtonSelect},
  props: {
    modelValue: Boolean,
    initDivision: String,
    target: Object,
    dtlItem: Object,
  },
  emits: ['update:modelValue', 'completed'],
  setup(props, {emit}) {

    const store = useStore();
    const {showConfirm, showMessage} = useAlert();

    const isLoading = ref(false);
    const isAgree = ref(false);
    const regCrseAplySttDcd = ref('');
    const reason = ref('');
    const lrnerObjs = ref([]);
    const learns = ref([]);
    const objItems = ref([]);
    const session = computed(() => store.state.auth.session);

    const editable = reactive({
      rtrcn: false,
      seqChg: false,
      absd: false,
      evl: false,
    });

    const setEditable = () => {
      // console.log(props.target);
      const currentTs = new Date().getTime();
      // 입과대기상태일 경우 - 취소만가능
      if (props.target.lrnTrgtGrpDtlSn === 0) {
        if (props.target.rtrcnPsbltYn === 'Y') {
          editable.rtrcn = (currentTs >= props.target.rtrcnBgngDt && currentTs < props.target.rtrcnEndDt);
        }
        // 입과완료상태일 경우 - 불참,회차변경 가능
      } else {
        if (props.target.absdAplyYn === 'Y') {
          editable.absd = (currentTs >= props.target.absdAplyBgngDt && currentTs < props.target.absdAplyEndDt);
        }
        if (props.target.seqChgYn === 'Y') {
          editable.seqChg = (currentTs >= props.target.seqChgBgngDt && currentTs < props.target.seqChgEndDt);
        }
      }

      if (editable.rtrcn) {
        regCrseAplySttDcd.value = crseAplyDcds.REG_CRSE_APLY_STT_CANCEL;
      } else if (editable.absd) {
        regCrseAplySttDcd.value = crseAplyDcds.REG_CRSE_APLY_STT_NOSW;
      } else if (editable.seqChg) {
        regCrseAplySttDcd.value = crseAplyDcds.REG_CRSE_APLY_STT_CHANGE;
      }
      // console.log(editable);
    };

    const changeMessages = computed(() => {
      if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CANCEL) {
        return {
          title: '신청취소',
          text: '신청취소를 신청하시겠습니까?',
          agree: '신청취소',
          complete: '신청취소가 신청되었습니다.',
        };

      } else if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_NOSW) {
        return {
          title: '불참(취소)',
          text: '불참(취소)을 신청하시겠습니까?',
          agree: '불참(취소) ',
          complete: '불참(취소)이 신청되었습니다.',
        };

      } else if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) {
        return {
          title: '회차변경',
          text: '회차변경을 신청하시겠습니까?',
          agree: '회차변경 ',
          complete: '회차변경이 신청되었습니다.',
        };
      }
      return {};
    });

    setEditable();

    const chgDistCrseSn = ref(0);
    const relToggle = ref(false);
    const rels = ref([]);
    const otherRels = computed(() => rels.value.filter(x => x.distCrseSn !== props.target.distCrseSn));
    const grpExist = ref(false);
    const grpDate = ref(0);
    const chgTarget = ref({});

    const validate = () => {
      let result = false;
      let msg = '';
      if (!isAgree.value) {
        msg = changeMessages.value.agree + ' 동의 체크해주세요.';
      } else if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE && chgDistCrseSn.value === 0) {
        msg = '변경할 차수를 선택해주세요.';
      } else {
        result = true;
      }

      if (!result) {
        showMessage(msg);
      }
      return result;
    };

    const validate2 = () => {
      if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CANCEL) {
        if (props.target.takeSttDcd === crseAplyDcds.TAKE_STT_COMPLETE) {
          showMessage('승인된 연수는 신청 취소가 불가능합니다.');
          return false;
        }

        if (!editable.rtrcn) {
          showMessage('해당 연수는 연수신청 취소가 불가능합니다.');
          return false;
        }
      }

      if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) {
        // if (props.target.takeSttDcd === crseAplyDcds.TAKE_STT_COMPLETE) {
        //   showMessage('승인된 연수는 회차변경이 불가능합니다.');
        //   return false;
        // }

        if (!editable.seqChg) {
          showMessage('해당 연수는 회차변경이 불가능합니다.');
          return false;
        }
      }
      return true;
    };

    const validate3 = () => {
      let result = true;
      let msg = '';
      grpExist.value = false;
      if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) {
        // 집합평가객체가 동일한 일자를 2개이상 가지고 있는 경우
        const grpEvlExist = lrnerObjs.value.filter(x => x.grpEvlCnt >= 2);
        if (grpEvlExist.length > 0) {
          const sameGrpEvlDate = grpEvlExist.filter(x => objItems.value.some(y => y.objBgngDt === x.objBgngDt));
          if (sameGrpEvlDate.length > 0) {
            grpExist.value = true;
            grpDate.value = timestampToDateFormat(sameGrpEvlDate[0].objBgngDt, 'yyyy.MM.dd');
          }
        }

        // 사이버평가객체가 동일한 일자를 2개이상 가지고 있는 경우
        const elseEvlExist = lrnerObjs.value.filter(x => x.gnrlEvlCnt >= 2);
        if (elseEvlExist.length > 0) {
          const sameElseEvlDate = elseEvlExist.filter(x => objItems.value.some(y => y.objBgngDt === x.objBgngDt));
          if (sameElseEvlDate.length > 0) {
            result = false;
            msg = '평가일자가 ['.concat(timestampToDateFormat(sameElseEvlDate[0].objBgngDt, 'yyyy.MM.dd'), '] 로 ' +
                '동일한 과정을<br>이미 2개 과정 신청하셨으므로<br>추가신청이 불가합니다.');
          }
        }
      }

      if (!result) {
        showMessage(msg);
      }
      return result;
    }

    const clickChange = () => {
      if (isLoading.value) return;

      isLoading.value = true;

      if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) {
          getValdList();
      }

      setTimeout(() => {
        if (validate() && validate2() && validate3()) {
          const params = {regCrseAplySttDcd: regCrseAplySttDcd.value, memo: reason.value};
          if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CANCEL) {// 취소일 경우
            params.distCrseSn = props.target.distCrseSn;
            params.causeDistCrseSn = 0;
            if (props.target.rtrcnAprvProc == '2' || props.target.rtrcnAprvProc == '9') {// 부점장 승인을 사용하지 않는경우
              params.aplyViceMgrAprvYn = 'N';
            } else {
              params.aplyViceMgrAprvYn = 'Y';
            }
            if (props.target.rtrcnAprvProc == '2' || props.target.rtrcnAprvProc == '3') {// 연수원 승인을 사용하는경우
              params.atrzPhase = 3;
            } else {
              params.atrzPhase = 1;
            }
          } else if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_NOSW) {// 불참일 경우
            params.distCrseSn = props.target.distCrseSn;
            params.causeDistCrseSn = 0;
            if (props.target.absdAprvProc == '2' || props.target.absdAprvProc == '9') {// 부점장 승인을 사용하지 않는경우
              params.aplyViceMgrAprvYn = 'N';
            } else {
              params.aplyViceMgrAprvYn = 'Y';
            }
            if (props.target.absdAprvProc == '2' || props.target.absdAprvProc == '3') {// 연수원 승인을 사용하는경우
              params.atrzPhase = 3;
            } else {
              params.atrzPhase = 1;
            }
          } else if (regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) {//회차 변경일 경우
            params.distCrseSn = chgDistCrseSn.value;
            params.causeDistCrseSn = props.target.distCrseSn;
            if (props.target.seqChgAprvProc == '2' || props.target.seqChgAprvProc == '9') {// 부점장 승인을 사용하지 않는경우
              params.aplyViceMgrAprvYn = 'N';
            } else {
              params.aplyViceMgrAprvYn = 'Y';
            }
            if (props.target.seqChgAprvProc == '2' || props.target.seqChgAprvProc == '3') {// 연수원 승인을 사용하는경우
              params.atrzPhase = 3;
            } else {
              params.atrzPhase = 1;
            }
          } else {
            return false;
          }
          let cMsg = '';
          if (grpExist.value) {
            cMsg += '집합평가일자가 ['.concat(grpDate.value,'] 로 동일한 과정을<br>이미 2개 이상 신청하셨습니다.<br>' +
                    '연수신청은 가능하나 수료에 차질이없는지<br>신중하게 결정하시기 바랍니다.<br>');
          }
          // console.log(params);
          showConfirm({
            title: changeMessages.value.title,
            text: cMsg.concat(changeMessages.value.text),
            callback: () => {
              store.dispatch(`course/${ACT_UPDATE_CRSE_REG_APLY}`, {
                regCrseAplySn: props.target.regCrseAplySn,
                params,
              }).then(res => {
                if (isSuccess(res)) {
                  showMessage(changeMessages.value.complete);
                  emit('completed');
                  closeModal();
                } else {
                  showMessage(getResult(res).message);
                  isLoading.value = false;
                }
              }).catch(e => {
                console.error(e);
                showMessage('오류가 발생했습니다.');
                isLoading.value = false;
              });
            },
            closeCallback: () => {
              isLoading.value = false;
            },

          });

        } else {
          isLoading.value = false;
        }
      },regCrseAplySttDcd.value === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE ? 600 : 0)
    };

    const closeModal = () => {
      emit('update:modelValue', false);
    };

    const limitOver = (peoplCnt, aplyRegCnt) => {
      return (peoplCnt > 0 && peoplCnt <= aplyRegCnt);
    };

    const excludeDist = [202333, 202334];
    // 회차 변경이 가능하면 관련 리스트 조회
    if (props.target.distCrseSn > 0) {
      getListFunc(`course/${ACT_GET_CRSE_REL_LIST}`, props.target.distCrseSn, rels, null, () => {
        // console.log(rels.value);
        const currentTs = new Date().getTime();
        rels.value = rels.value.filter(x =>
            // x.seqChgYn === 'Y' && (!limitOver(x.peoplCnt, x.aplyRegCnt) && currentTs >= x.seqChgBgngDt && currentTs <= x.seqChgEndDt)
            x.seqChgYn === 'Y' && (currentTs >= x.seqChgBgngDt && currentTs <= x.seqChgEndDt)
        ).map(y => ({...y, crseNm: y.crseNm.concat(limitOver(y.peoplCnt, y.aplyRegCnt) ? ' - 신청정원초과' : '')}));
        if (props.dtlItem.digiDeptTyDcd === '2112001' && session.value.susdyCdDcd === '2109001') {
          rels.value = rels.value.filter(y => !excludeDist.includes(y.distCrseSn));
        }
      });
    }

    getListFunc(`course/${ACT_GET_CRSE_DIST_OBJ_LRNER}`, {}, lrnerObjs, null);
    getListFunc(`learn/${ACT_GET_LEARN_HISTORY_LIST}`, {}, learns, null, () => {
      learns.value = learns.value.filter(y => y.auditCfmtnCd === 'Y').filter(x => x.fnshYn === 'Y');
    });
    const getValdList = () => {
      getListFunc(`course/${ACT_GET_CRSE_DIST_OBJ_APPLY}`, chgDistCrseSn.value, objItems, null);
    }

    const changeThis = () => {
      chgTarget.value = otherRels.value.filter(x => x.distCrseSn === chgDistCrseSn.value)[0];
    }

    return {
      isAgree,
      regCrseAplySttDcd,
      reason,
      chgDistCrseSn,
      relToggle,
      otherRels,
      editable,
      clickChange,
      closeModal,
      timestampToDateFormat,

      crseAplyDcds,
      changeThis,
      chgTarget,
    };

  },
};
</script>
