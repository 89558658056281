<template>
  <main class="kb-main" :id="`${isMobile ? 'kb-educourse' : 'kb-educourse-status'}`">
    <LxpMobileHeader v-if="isMobile" :title="`${'연수신청 현황조회'.concat(' ',rowCnt,'건')}`">
    </LxpMobileHeader>
    <lxp-main-header v-else :show-breadcrumb="true" :show-title="true" title="연수신청 현황조회"/>
    <!-- main-content -->
    <div class="main-content main-component" style="padding-bottom: 60px">
      <div class="educourse-list-container">
        <template v-if="isMobile">
          <ul class="educourse-list">
            <template v-if="rowCnt > 0">
            <template v-for="(item, index) in items" :key="index">
              <template v-if="((item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_APPLY   ||
                                item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) &&
                                item.takeSttDcd !== crseAplyDcds.TAKE_STT_REJECT)">
            <li class="list-item" v-if="item.rnum === 1 && item.splCrseTyCdDcd !== '2066022'">
              <!-- educourse-list > educourse -->
              <div class="educourse">
                <div class="educourse-content border-0 pt-2">
                  <div class="educourse-summary">
                    <p class="title">{{ item.crseNm }}</p>
                    <p class="status">
                      {{ item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_NOSW ? '불참(취소)' : item.regCrseAplySttNm }} -
                      {{ item.splCrseTyCdDcd === '2066030' ? '선정중' : item.takeSttNm }}</p>
                  </div>
                  <div class="educourse-date">
                    <div class="date-item">
                      <div class="title">연수기간</div>
                      <div>{{ timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd.w') }} -
                        {{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd.w') }}
                      </div>
                    </div>
<!--                    <div class="date-item">-->
<!--                      <div class="title">신청</div>-->
<!--                      <div>{{ timestampToDateFormat(item.aprvDmndDt, 'yyyy.MM.dd.w') }}</div>-->
<!--                    </div>-->
                    <div class="date-item">
                      <div class="title">취소기간</div>
                      <div>
                        <template v-if="item.rtrcnPsbltYn === 'Y'" class="td-text">
                          {{ timestampToDateFormat(item.rtrcnBgngDt, 'yyyy.MM.dd.w') }} -
                          {{ timestampToDateFormat(item.rtrcnEndDt, 'yyyy.MM.dd.w') }}
                        </template>
                        <template v-else class="td-text">-</template>
                      </div>
                    </div>
                  </div>
                  <div class="educourse-actions">
                    <!--신청 상태-->
                    <template v-if="item.regCrseAplySttDcd === '2049001'">
                      <!--신청 중-->
                      <button v-if="item.lrnTrgtGrpDtlSn === 0 && item.takeSttDcd === '2050003'" class="kb-btn-cancel"
                              type="button" @click="showEditModal(index)">
                        <span class="text">신청취소</span>
                      </button>

                      <!-- 불참/회차변경기간이 아니면서 평가면제신청 가능한 기간인 경우  -->
                      <template  v-if="item.evlExptAplyYn === 'Y' && item.evlExptAplyBgngDt <= currentTs && currentTs <= item.evlExptAplyEndDt" >
                        <button v-if="item.lrnTrgtGrpDtlSn > 0 && item.takeSttDcd === '2050001'" class="kb-btn-cancel"
                                type="button" @click="showExptEvlModal(index)">
                          <span class="text">평가면제 신청</span>
                        </button>
                      </template>
                      <!--대상자 확정-->
                      <template v-else>
                        <button v-if="item.lrnTrgtGrpDtlSn > 0 && item.takeSttDcd === '2050001'" class="kb-btn-cancel"
                                type="button" @click="showEditModal(index)">
                          <span class="text">불참(취소)/회차변경</span>
                        </button>
                      </template>

                    </template>
                    <template v-else>
                      <!--신청대기중-->
                      <button v-if="item.lrnTrgtGrpDtlSn > 0 && item.takeSttDcd !== '2050003'" class="kb-btn-cancel"
                              type="button" @click="showEditModal(index)">
                        <span class="text">불참(취소)/회차변경x</span>
                      </button>
                    </template>
                  </div>
                </div>
              </div>
            </li>
              </template>
            </template>
            </template>
            <template v-else>
              <div class="text-center" style="padding-top: 10px">연수신청내역이 존재하지 않습니다.</div>
            </template>
          </ul>
        </template>
        <template v-else>
          <!-- list-top -->
          <div class="list-top pb-4">
            <div class="top-column">
              <p class="title text-muted">총 {{ rowCnt }}건</p>
            </div>
          </div>
          <!-- //list-top -->
          <!-- status-tablelist -->
          <div class="status-tablelist">
            <div class="kb-table kb-table-bordered" style="padding-left: 0px">
              <table>
                <colgroup>
                  <col style="width:auto;">
                  <col style="width:170px;">
                  <col style="width:140px;">
                  <col style="width:160px;">
                  <col style="width:160px;">
                  <col style="width:120px;">
                  <col style="width:120px;">
                  <col style="width:140px;">
                </colgroup>
                <thead>
                <tr>
                  <th><span class="th-title">과정차수명</span></th>
                  <th><span class="th-title">연수기간</span></th>
                  <th><span class="th-title">평가일</span></th>
                  <th><span class="th-title">불참(취소)가능기간</span></th>
                  <th><span class="th-title">회차변경가능기간</span></th>
                  <th><span class="th-title">신청구분</span></th>
                  <th><span class="th-title">입과상태</span></th>
                  <th><span class="th-title">불참(취소)/회차변경</span></th>
                </tr>
                </thead>
                <tbody style="padding-left: 0px">
                <template v-if="rowCnt > 0">
                  <template v-for="(item, index) in items" :key="index">
                    <template v-if="((item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_APPLY   ||
                                      item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE ||
                                      item.takeSttDcd === crseAplyDcds.TAKE_STT_PENDING) &&
                                      item.takeSttDcd !== crseAplyDcds.TAKE_STT_REJECT)">
                      <tr v-if="item.rnum === 1 && item.splCrseTyCdDcd !== '2066022'" style="padding-left: 0px">
                        <td><strong class="td-text">{{ item.crseNm }}</strong></td>
                        <td><strong class="td-text">
                          {{ timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd.w') }} -
                          {{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd.w') }}</strong>
                        </td>
                        <td>
                          <strong class="td-text">{{ item.aprvDmndDt ? timestampToDateFormat(item.aprvDmndDt, 'yyyy.MM.dd.w') : '-' }}</strong>
                        </td>
                        <td>
                          <template v-if="item.lrnTrgtGrpDtlSn === 0">
                            <strong v-if="item.rtrcnPsbltYn === 'Y'" class="td-text">
                              {{ timestampToDateFormat(item.rtrcnBgngDt, 'yyyy.MM.dd.w') }} -
                              {{ timestampToDateFormat(item.rtrcnEndDt, 'yyyy.MM.dd.w') }}
                            </strong>
                            <strong v-else class="td-text">-</strong>
                          </template>
                          <template v-else>
                            <strong v-if="item.absdAplyYn === 'Y'" class="td-text">
                              {{ timestampToDateFormat(item.absdAplyBgngDt, 'yyyy.MM.dd.w') }} -
                              {{ timestampToDateFormat(item.absdAplyEndDt, 'yyyy.MM.dd.w') }}
                            </strong>
                            <strong v-else class="td-text">-</strong>
                          </template>
                        </td>
                        <td>
                          <template v-if="item.lrnTrgtGrpDtlSn > 0">
                            <strong v-if="item.seqChgYn === 'Y'" class="td-text">
                              {{ timestampToDateFormat(item.seqChgBgngDt, 'yyyy.MM.dd.w') }} -
                              {{ timestampToDateFormat(item.seqChgEndDt, 'yyyy.MM.dd.w') }}
                            </strong>
                            <strong v-else class="td-text">-</strong>
                          </template>
                          <template v-else>
                            <strong class="td-text">-</strong>
                          </template>
                        </td>
                        <td>
                          <strong class="td-text"
                                    :class="{'text-red': item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CANCEL ||
                                                         item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_NOSW}">
                          {{ item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_NOSW ? '불참(취소)' : item.regCrseAplySttNm }}
                          </strong>
                        </td>
                        <td>
                          <strong :class="item.takeSttDcd == crseAplyDcds.TAKE_STT_COMPLETE ? 'text-extra' : 'td-text'">
                            {{
                              item.takeSttDcd == crseAplyDcds.TAKE_STT_COMPLETE ?
                                  item.splCrseTyCdDcd === '2066030' ? '선정중' :
                                  item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_APPLY   ||
                                  item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE  ? item.takeSttNm : '-'
                              : item.takeSttNm
                            }}
                          </strong>
                        </td>
                        <td class="align-center">

                          <template v-if="item.regCrseAplySttDcd === '2049001'">
                            <!--신청 중-->
                            <button v-if="item.lrnTrgtGrpDtlSn === 0 && item.takeSttDcd === '2050003'" style="width: 150px"
                                    class="kb-btn kb-btn-outline rounded-lg" type="button" @click="showEditModal(index)">
                              <span class="text">신청취소</span>
                            </button>

                            <!-- 불참/회차변경기간이 아니면서 평가면제신청 가능한 기간인 경우  -->
                            <template  v-if="item.evlExptAplyYn === 'Y' && item.evlExptAplyBgngDt <= currentTs && currentTs <= item.evlExptAplyEndDt" >
                              <button v-if="item.lrnTrgtGrpDtlSn > 0 && item.takeSttDcd === '2050001'" style="width: 150px"
                                      class="kb-btn kb-btn-outline rounded-lg" type="button" @click="showExptEvlModal(index)">
                                <span class="text">평가면제 신청</span>
                              </button>
                            </template>
                            <!--대상자 확정-->
                            <template v-else>
                              <button v-if="item.lrnTrgtGrpDtlSn > 0 && item.takeSttDcd === '2050001'" style="width: 150px"
                                      class="kb-btn kb-btn-outline rounded-lg" type="button" @click="showEditModal(index)">
                                <span class="text">불참(취소)/회차변경</span>
                              </button>
                            </template>


                          </template>
                          <template v-else>
                            <button v-if="item.lrnTrgtGrpDtlSn > 0 && item.takeSttDcd !== '2050003'" style="width: 150px"
                                    class="kb-btn kb-btn-outline rounded-lg" type="button" @click="showEditModal(index)">
                              <span class="text">불참(취소)/회차변경</span>
                            </button>
                          </template>
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="8" class="text-center" style="padding-top: 36px;padding-bottom: 0px">
                      <span class="text">연수신청내역이 존재하지 않습니다.</span>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </template>
        <div style="margin-bottom: 30px"></div>
        <!--        <CommonPaginationFront :paging="paging" :page-func="pagingFunc"/>-->
        <!-- //status-tablelist -->
      </div>

      <template v-if="isMobile">
        <header class="kb-header" id="kb-header">
          <div class="header-component"><!-- header-title -->
            <div class="header-column header-title">연수신청 이력조회 {{ histItems.length }}건</div>
          </div>
        </header>
        <div class="educourse-list-container">
        <ul class="educourse-list">
          <template v-if="histItems.length > 0">
          <li class="list-item" v-for="(item, index) in histItems" :key="index">
            <div class="educourse">
              <div class="educourse-content border-0 pt-2">
                <div class="educourse-summary">
                  <p class="title">{{ item.crseNm }}</p>
                  <p class="status">
                    {{ item.regCrseAplySttNm }} -
                    {{ item.takeSttNm }}</p>
                </div>
                <div class="educourse-date">
                  <div class="date-item">
                    <div class="title">결재자</div>
                    <div>{{ item.lrnerNm }} -
                      {{ timestampToDateFormat(item.mdfcnDt, 'yyyy.MM.dd.w') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          </template>
          <template v-else>
            <li>
              <div>
                <div class="text-center" style="padding-top: 10px">연수신청이력이 존재하지 않습니다.</div>
              </div>
            </li>
          </template>
        </ul>
        </div>
      </template>
      <template v-else>
        <div class="main-header">
        <div class="header-title">
          <h2 class="title">연수신청 이력조회</h2>
        </div>
        <div class="educourse-list-container">
          <div class="list-top pb-4">
            <div class="top-column">
              <p class="title text-muted">총 {{ histItems.length }}건</p>
            </div>
          </div>
          <div class="status-tablelist">
            <div class="kb-table kb-table-bordered" style="padding-left: 0px">

              <table>
                <colgroup>
                  <col style="width:auto;">
                  <col style="width:120px;">
                  <col style="width:120px;">
                  <col style="width:120px;">
                  <col style="width:140px;">
                </colgroup>
                <thead>
                <tr>
                  <th><span class="th-title">과정차수명</span></th>
                  <th><span class="th-title">결재자</span></th>
                  <th><span class="th-title">신청구분</span></th>
                  <th><span class="th-title">결재상태</span></th>
                  <th><span class="th-title">결재일</span></th>
                </tr>
                </thead>
                <tbody style="padding-left: 0px">
                <template v-if="histItems.length > 0">
                  <template v-for="(item, index) in histItems" :key="index">
                    <tr style="padding-left: 0px">
                      <td><strong class="td-text">{{ item.crseNm }}</strong></td>
                      <td><strong class="td-text">{{ item.lrnerNm }}</strong></td>
                      <td><strong class="td-text">{{ item.regCrseAplySttNm }}</strong></td>
                      <td><strong class="td-text">{{ item.takeSttNm }}</strong></td>
                      <td><strong class="td-text">{{ timestampToDateFormat(item.mdfcnDt, 'yyyy.MM.dd.w') }}</strong></td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="5" class="text-center" style="padding-top: 36px;padding-bottom: 0px">
                      <span class="text">연수신청이력이 존재하지 않습니다.</span>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
      </template>
    </div>
    <!-- //main-content -->
    <ApplyStatusEditModal
        v-if="edit.toggle && !isMobile"
        v-model="edit.toggle"
        :target="edit.target"
        :dtlItem="dtlItem"
        @completed="getCourseRegList"
    />

    <!--    <ApplyTrainModal-->
    <!--        v-if="detail.toggle"-->
    <!--        v-model="detail.toggle"-->
    <!--        :params="detail.target"-->
    <!--        :is-view="true"-->
    <!--    />-->

  </main>
</template>

<script>

import LxpMainHeader from '@/components/common/LxpMainHeader';
import ApplyStatusEditModal from '@/components/apply/ApplyStatusEditModal';
import {reactive, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {getListFunc, getValueByQuery, pushQuery, getFunc} from '@/assets/js/ui.init';
import {ACT_GET_CRSE_REG_APLY_LIST, ACT_GET_CRSE_REG_APLY_HIST_LIST, ACT_GET_DIGITAL_DTL} from '@/store/modules/course/course';
import {timestampToDateFormat} from '@/assets/js/util';
import {crseAplyDcds} from '@/assets/js/modules/common/common';
import {useAlert} from '@/assets/js/modules/common/alert';
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import navigationUtils from '@/assets/js/navigationUtils';

export default {
  name: 'ApplyStatus',
  components: {
    ApplyStatusEditModal,
    LxpMainHeader,
    LxpMobileHeader,
  },
  setup() {

    const router = useRouter();
    const isMobile = navigationUtils.any();
    const route = useRoute();
    const items = ref([]);
    const {showMessage} = useAlert();
    const rowCnt = ref(0);
    const histItems = ref([]);

    const edit = reactive({
      toggle: false,
      target: null,
    });

    const paging = reactive({
      pageNo: getValueByQuery(route.query, 'pageNo', true, 1),
      pageSize: 100,
      totalCount: 0,
    });

    const dtlItem = reactive({
      lrnerId: 0,
      reqYn: '',
      digiDeptTyDcd: '',
      digiDeptTyNm: '',
      preYn: '',
      basicYn: '',
      advnYn: '',
    });

    const currentTs = new Date().getTime();

    const getDivision = (item) => {
      // console.log(item.lrnTrgtGrpDtlSn, item.regCrseAplySttDcd, item.rtrcnPsbltYn, item.absdAplyYn, item.seqChgYn);
      let division = true;
      let msg = '';

      if (item.takeSttDcd) {
        // 입과 완료
        if (item.lrnTrgtGrpDtlSn > 0) {
          if (item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_APPLY ||
              item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE ) {
            // console.log(item);
            if (item.absdAplyYn === 'N' && item.seqChgYn === 'N') {
              division = false;
              msg = '해당연수는 불참(취소)/회차변경이 불가합니다.';
            }
            else {
              if ((currentTs < item.absdAplyBgngDt || currentTs > item.absdAplyEndDt) &&
                  (currentTs < item.seqChgBgngDt || currentTs > item.seqChgEndDt) )
              {
                division = false;
                msg = '불참(취소)/회차변경 기간이 아닙니다.';
              }
            }
          }
        }
        // 입과 대기
        else {
          // 취소일 경우
          if (item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_APPLY) {
            if (item.rtrcnPsbltYn === 'Y') {
              if (currentTs >= item.rtrcnBgngDt && currentTs < item.rtrcnEndDt) {
                // cancel
                division = true;
              } else {
                division = false;
                msg = '신청취소 기간이 아닙니다.';
              }
            } else {
              division = false;
              msg = '해당연수는 신청취소가 허용되지 않습니다.';
            }
          } else {
            division = false;
            msg = '신청취소 기간이 아닙니다.';
          }
        }
      }

      if (!division) {
        showMessage(msg);
      }

      return division;
    };

    const getCourseRegList = () => {
      getListFunc(`course/${ACT_GET_CRSE_REG_APLY_LIST}`, {...paging}, items, paging, () => {
        items.value = items.value.map(x => ({...x}));
        items.value = items.value.filter(x => x.rnum === 1 && x.splCrseTyCdDcd !== '2066022' && x.splCrseTyCdDcd !== '2066031'&& x.splCrseTyCdDcd !== '2066030').
        filter(y => ((y.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_APPLY  ||
                y.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) &&
            y.takeSttDcd !== crseAplyDcds.TAKE_STT_REJECT));
        rowCnt.value = items.value.
            filter(x => x.rnum === 1 && x.splCrseTyCdDcd !== '2066022' && x.splCrseTyCdDcd !== '2066031'&& x.splCrseTyCdDcd !== '2066030').
            filter(y => ((y.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_APPLY  ||
                          y.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) &&
                          y.takeSttDcd !== crseAplyDcds.TAKE_STT_REJECT)).
            length;
      });

      getListFunc(`course/${ACT_GET_CRSE_REG_APLY_HIST_LIST}`, {...paging}, histItems, paging, () => {});
    };

    const getCourseDigital = () => {
      getFunc(`course/${ACT_GET_DIGITAL_DTL}`, {}, dtlItem, () => {});
    };

    const pagingFunc = (pageNo) => {
      if (pageNo) {
        pushQuery({pageNo});
      } else {
        pushQuery({});
      }
      paging.pageNo = pageNo;
      getCourseRegList();
    };

    const showEditModal = (index) => {
      // console.log(getDivision(items.value),items.value[index]);
      if (getDivision(items.value[index])) {
        if (isMobile) {
          router.push({name: 'ApplyStatusEdit', params: {distCrseSn: items.value[index].distCrseSn}})
        } else {
          edit.target = items.value[index];
          edit.toggle = true;
        }
      }
    };


    const showExptEvlModal = (index) => {
        console.log(index);
        router.push({name: 'BoardMst', params: {boardId: "crse-apply-nosw"}})
    };


    getCourseRegList();
    getCourseDigital();

    const getAtrzStat = (item) => {
      // console.log(item);
      let procVal = '';
      let phazVal = 0;

      if (item.regCrseAplySttDcd === '2049001') {
        procVal = item.aplyAprvProc;
      } else if (item.regCrseAplySttDcd === '2049002') {
        procVal = item.rtrcnAprvProc;
      } else if (item.regCrseAplySttDcd === '2049003') {
        procVal = item.seqChgAprvProc;
      } else if (item.regCrseAplySttDcd === '2049004') {
        procVal = item.absdAprvProc;
      }

      if (procVal === '1' || procVal === '9') {
        phazVal = 1;
      } else if (procVal === '2' || procVal === '3') {
        phazVal = 3;
      } else {
        phazVal = 0;
      }

      if (item.lrnerId == item.rgtrId) {
        if (phazVal === 1) {
          if (procVal === '1') {
            if (item.atrzMdfcnDt01 === null) {
              return '부점장 대기';
            } else {
              if (item.takeSttNm !== '대기') {
                return '부점장'.concat(' ', item.takeSttNm);
              }
            }
          } else {
            return '-';
          }
        } else if (phazVal === 3) {
          if (procVal === '2' || procVal === '3') {
            if (item.atrzMdfcnDt01 === null) {
              return '부점장 대기';
            } else if (item.atrzMdfcnDt03 === null) {
              if (item.takeSttNm !== '대기') {
                if (item.atrzMdfcnDt02 === null) {
                  return '부점장'.concat(' ', item.takeSttNm);
                } else {
                  return '연수원'.concat(' ', item.takeSttNm);
                }
              } else {
                return '연수원 대기';
              }
            } else {
              if (item.takeSttNm !== '대기') {
                return '연수원'.concat(' ', item.takeSttNm);
              }
            }
          } else {
            return '-';
          }
        } else {
          return '-';
        }
      } else {
        return '-';
      }
    };

    return {
      items,
      histItems,
      paging,
      edit,
      rowCnt,
      dtlItem,

      pagingFunc,
      showEditModal,
      timestampToDateFormat,
      getCourseRegList,
      crseAplyDcds,
      getAtrzStat,
      isMobile,
      currentTs,
      //평가면제 신청
      showExptEvlModal
    };
  },
};
</script>

<style scoped>

.lxp-layout .kb-table-bordered th, .lxp-layout .kb-table-bordered td {
  min-height: 68px;
  padding-left: 0px;
  padding-right: 0;
  text-align: left;
}

.lxp-layout .kb-table-bordered strong.text-extra {
  display: inline-flex;
  min-height: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
  font: normal 1rem/20px var(--kb-font-KBFGTextB);
  color: var(--kb-primary) !important;
}

.header-title-extra {
  position: relative;
  text-align: center;
  font: normal 32px/1.25 var(--kb-font-KBFGDisplayB);
  font-family: var(--kb-font-KBFGText);
}

</style>
